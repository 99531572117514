// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.score-page {
    text-align: center;
    padding: 20px;
    position: relative;
  }
  
  .results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .results-table th, .results-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .results-table th {
    background-color: #f2f2f2;
  }
  
  .results-table tr.correct {
    background-color: #d4edda;
  }
  
  .results-table tr.incorrect {
    background-color: #f8d7da;
  }
  
  .results-table tr.unanswered {
    background-color: #fff3cd;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff4d4d;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
  }

  .close-button:hover {
    background-color: #ff1a1a;
  }`, "",{"version":3,"sources":["webpack://./src/pages/ScorePage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".score-page {\n    text-align: center;\n    padding: 20px;\n    position: relative;\n  }\n  \n  .results-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  .results-table th, .results-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .results-table th {\n    background-color: #f2f2f2;\n  }\n  \n  .results-table tr.correct {\n    background-color: #d4edda;\n  }\n  \n  .results-table tr.incorrect {\n    background-color: #f8d7da;\n  }\n  \n  .results-table tr.unanswered {\n    background-color: #fff3cd;\n  }\n\n  .close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background-color: #ff4d4d;\n    border: none;\n    color: white;\n    padding: 10px;\n    cursor: pointer;\n    font-size: 16px;\n    border-radius: 50%;\n  }\n\n  .close-button:hover {\n    background-color: #ff1a1a;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
