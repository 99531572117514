import React from 'react';


const NoPage = () => {
  return (
	<div>
	  <h1>404 - Page Not Found Error.</h1>
	</div>
  );
};

export default NoPage;