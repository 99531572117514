// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-container {
    margin: 1rem 0;
  }
  
  .question-container h2 {
    margin-bottom: 1rem;
  }
  
  .question-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .question-container .option {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .question-container .option:hover {
    background-color: #e0e0e0;
  }
  
  .question-container .option.selected {
    background-color: #007bff;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/components/Question.css"],"names":[],"mappings":"AAAA;IACI,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,oBAAoB;IACpB,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".question-container {\n    margin: 1rem 0;\n  }\n  \n  .question-container h2 {\n    margin-bottom: 1rem;\n  }\n  \n  .question-container ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .question-container .option {\n    padding: 0.5rem 1rem;\n    margin: 0.5rem 0;\n    background-color: #f0f0f0;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n  }\n  \n  .question-container .option:hover {\n    background-color: #e0e0e0;\n  }\n  \n  .question-container .option.selected {\n    background-color: #007bff;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
